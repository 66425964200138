import { readState } from "@/__main__/app-state.mjs";
import getData from "@/__main__/get-data.mjs";
import { getEvents, getParticipantInfo } from "@/feature-battles/api.mjs";
import {
  fetchUserOptIns,
  getCurrentFilters,
} from "@/feature-battles/fetch-event-overview.mjs";
import EventsModel from "@/feature-battles/model-events.mjs";
import ParticipantInfoModel from "@/feature-battles/model-participants-info.mjs";
import {
  getCurrentEvents,
  // mergeEvents
} from "@/feature-battles/utils.mjs";

async function fetchLoadingScreen() {
  const filters = await getCurrentFilters();
  await getData(getEvents(filters), EventsModel, ["battles", "eventMap"], {
    // Prevent unnecessary calls to cms if we have the data, trust the data.
    shouldFetchIfPathExists: false,
  });

  fetchUserOptIns(filters, {
    skipSafetyCheck: true,
    shouldFetchIfPathExists: false,
  });

  const eventsWCapacity = getCurrentEvents(readState.battles.eventMap).filter(
    ({ capacity }) => capacity,
  );
  if (eventsWCapacity.length)
    return getData(
      getParticipantInfo({ ids: eventsWCapacity.map(({ id }) => id) }),
      ParticipantInfoModel,
      ["battles", "eventParticipantInfo"],
      {
        // mergeFn: mergeEvents,
        skipSafetyCheck: true,
      },
    );
}

export default fetchLoadingScreen;
