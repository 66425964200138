import React from "react";

import { EVENTS as GAME_INTEGRATION_EVENTS } from "@/__main__/refs.mjs";
import eventBus from "@/app/app-event-bus.mjs";
import { HUB_NAME_MAP } from "@/app/constants.mjs";
import { EVENT_USER_LOGOUT } from "@/feature-auth/utils/auth-actions.mjs";
import {
  clearUserData,
  createBattlesPath,
  removeBattlesPath,
} from "@/feature-battles/actions.mjs";
import { ProfileTileAd } from "@/feature-battles/BattlesAds.jsx";
import { BattlesPostMatch } from "@/feature-battles/BattlesPostMatch.jsx";
import { HideEventPromosSetting } from "@/feature-battles/BattlesSettings.jsx";
import {
  BATTLES_NAME,
  // BREADCRUMBS,
  HANDLED_GAMES,
  HUB_SYMBOL_BATTLES,
} from "@/feature-battles/constants.mjs";
import {
  clearDismissedEvents,
  getOverlayData,
  lolMatchEnd,
  lolMatchStart,
  valMatchEnd,
} from "@/feature-battles/dev-helpers.mjs";
import { onOverlayBroadcast } from "@/feature-battles/event-hooks.mjs";
import {
  getLeagueOverlayInfo,
  onEnterGame as onEnterLoLGame,
  onExitGame as onExitLoLGame,
} from "@/feature-battles/event-hooks-lol.mjs";
import { fetchBattlesAdEvents } from "@/feature-battles/fetch-event-overview.mjs";
import fetchLoadingScreen from "@/feature-battles/fetch-loading-screen.mjs";
import { fetchPostMatch } from "@/feature-battles/fetch-post-match.mjs";
import battlesRefs from "@/feature-battles/refs.mjs";
import { battlesGameRoutes, battlesRoutes } from "@/feature-battles/routes.mjs";
import fnRefs from "@/game-fortnite/refs.mjs";
import {
  EVENT_LOL_ENTER_GAME,
  EVENT_LOL_EXIT_GAME,
  EVENT_LOL_GET_OVERLAY_DATA,
} from "@/game-lol/constants/constants.mjs";
import LoLDefinition from "@/game-lol/definition.mjs";
import lolFetchLoadingScreenDataRefs from "@/game-lol/fetches/lol-fetch-loading-screen-data.refs.mjs";
import lolFetchPostmatchDataRefs from "@/game-lol/fetches/lol-fetch-postmatch-data.refs.mjs";
import LoadingScreenRefs from "@/game-lol/LoadingScreen.refs.jsx";
import lolRefs from "@/game-lol/refs.mjs";
import settingsRefs from "@/hub-settings/refs.mjs";
import gameRoutes from "@/routes/game.mjs";
import routes, { appRoutes } from "@/routes/routes.mjs";
import routesRefs from "@/routes/routes.refs.mjs";
import { reRender } from "@/shared/EventedRender.jsx";
import matchTileAdRefs from "@/shared/match-tile-ad.refs.mjs";
// import pageHeaderRefs from "@/shared/page-header.refs.mjs";
import globals from "@/util/global-whitelist.mjs";
import { removeFromArray } from "@/util/helpers.mjs";
import mapOriginalRefs from "@/util/map-original-refs.mjs";
import subscribeEvents from "@/util/subscribe-events.mjs";

const devHelpers = {
  clearDismissedEvents,
  getOverlayData,
  lol: {
    matchStart: lolMatchStart,
    matchEnd: lolMatchEnd,
  },
  val: {
    matchEnd: valMatchEnd,
  },
  get allowCustomGames() {
    return battlesRefs.allowCustomGames;
  },
  set allowCustomGames(val) {
    battlesRefs.allowCustomGames = val;
  },
};

const originals = mapOriginalRefs({
  routesRefs,
  lolRefs,
  fnRefs,
  lolFetchPostmatchDataRefs,
  settingsRefs,
  LoadingScreenRefs,
  lolFetchLoadingScreenDataRefs,
  // pageHeaderRefs,
  matchTileAdRefs,
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const eventListener = subscribeEvents(eventBus, [
  [EVENT_USER_LOGOUT, clearUserData],
  [GAME_INTEGRATION_EVENTS.EVENT_OVERLAY_BROADCAST, onOverlayBroadcast],

  // lol
  [EVENT_LOL_GET_OVERLAY_DATA, getLeagueOverlayInfo],
  [EVENT_LOL_ENTER_GAME, onEnterLoLGame],
  [EVENT_LOL_EXIT_GAME, onExitLoLGame],
]);

const navLinkBattlesLoL = {
  href: `/battles/lol/discover`,
  text: ["battles:battles", "Battles"],
};

export function setup() {
  // State
  createBattlesPath();

  // Routes
  for (const battlesRoute of battlesRoutes) {
    routes.push(battlesRoute);
    appRoutes.push(battlesRoute);
  }
  for (const gameSymbol of Object.getOwnPropertySymbols(HANDLED_GAMES)) {
    const r = battlesGameRoutes[gameSymbol];
    gameRoutes[gameSymbol].push(...r);
    routes.push(...r);
  }

  if (LoLDefinition.isActive === true)
    LoLDefinition.navLinks.push(navLinkBattlesLoL);

  HUB_NAME_MAP[HUB_SYMBOL_BATTLES] = BATTLES_NAME;

  // Events
  eventListener.start();

  // Refs
  originals.append({
    lolFetchPostmatchDataRefs: {
      fetchFunctions: [fetchPostMatch],
    },
    /* DISABLE AFTER EVENTS */
    lolFetchLoadingScreenDataRefs: {
      fetchFunctions: [fetchLoadingScreen],
    },
    settingsRefs: {
      settingsListItems: [HideEventPromosSetting],
    },
    /* DISABLE AFTER EVENTS */
    routesRefs: {
      defaultRouteSideEffects: [fetchBattlesAdEvents],
    },
    /* DISABLE AFTER EVENTS */
    matchTileAdRefs: {
      tiles: [ProfileTileAd],
    },
  });
  originals.set({
    lolRefs: {
      PostMatchLayout: BattlesPostMatch,
    },
    /* DISABLE AFTER EVENTS */
    LoadingScreenRefs: {
      battlesPromo: React.lazy(
        () => import("@/feature-battles/BattlesLoadingScreenPromo.jsx"),
      ),
    },
    // pageHeaderRefs: {
    //   breadcrumbs: {
    //     [BATTLES_NAME]: BREADCRUMBS,
    //   },
    // },
  });

  // Dev
  globals.__BLITZ_DEV__.battles = devHelpers;

  reRender();
}

export function teardown() {
  // Routes
  for (const battlesRoute of battlesRoutes) {
    appRoutes.splice(appRoutes.indexOf(battlesRoute), 1);
    routes.splice(routes.indexOf(battlesRoute), 1);
  }
  for (const gameSymbol of Object.getOwnPropertySymbols(HANDLED_GAMES)) {
    const a = gameRoutes[gameSymbol];
    for (const route of battlesGameRoutes[gameSymbol]) {
      a.splice(a.indexOf(route), 1);
      routes.splice(routes.indexOf(route), 1);
    }
  }

  // Custom
  removeFromArray(LoLDefinition.navLinks, navLinkBattlesLoL);
  delete HUB_NAME_MAP[HUB_SYMBOL_BATTLES];

  // Events
  eventListener.end();

  // Refs
  originals.restore();

  // State
  removeBattlesPath();

  // Dev
  delete globals.__BLITZ_DEV__.battles;

  reRender();
}
